<template>
	<b-modal id="edit-user" size="lg" title="Edit User" ref="modal" ok-title="Update" @ok="handleOk" @show="onReset"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<div>
					<b-tabs>
						<b-tab title="Primary Information" active>
							<b-row class="my-2">
								<b-col lg="6" md="12" sm="12">
									<b-form-group label="Company">
										<v-select name="Company" class="style-chooser" label="text"
											:options="companyOptions" :reduce="(company) => company.value"
											v-model="selCompany" v-validate="'selectRequired'">
											<template v-slot:no-options="{ search, searching }">
												<template v-if="searching">
													No results found for
													<em>
														<strong>{{ search }}</strong>
													</em>
												</template>
												<em :style="{ opacity: 0.5 }" v-else>
													Start typing to search for a company
												</em>
											</template>
										</v-select>
										<span v-show="errors.has('Company')" class="help-block">{{
											errors.first('Company')
										}}</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-2">
								<b-col lg="4" md="12" sm="12">
									<b-form-group label="First Name" label-for="firstName" description>
										<b-form-input name="First Name" type="text" v-model="form.firstName" v-validate="{
											required: true,
											regex: nameRegex,
										}" placeholder="First Name" maxlength="30" />
										<span v-show="errors.has('First Name')" class="help-block">{{
											errors.first('First Name')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="12" sm="12">
									<b-form-group label="Middle Name" label-for="middleName" description>
										<b-form-input name="Middle Name" type="text" v-model="form.middleName"
											v-validate="{
												required: false,
												regex: nameRegex,
											}" placeholder="Middle Name" maxlength="30" />
										<span v-show="errors.has('Middle Name')" class="help-block">{{
											errors.first('Middle Name')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="12" sm="12">
									<b-form-group label="Last Name" label-for="lastName" description>
										<b-form-input name="Last Name" type="text" v-model="form.lastName" v-validate="{
											required: true,
											regex: nameRegex,
										}" placeholder="Last Name" maxlength="30" />
										<span v-show="errors.has('Last Name')" class="help-block">{{
											errors.first('Last Name')
										}}</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-2">
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Job Position" label-for="position" description>
										<b-form-input name="Position" type="text" v-model="form.position" v-validate="{
											required: true,
											regex: positionRegex,
										}" placeholder="Job Position" maxlength="60" />
										<span v-show="errors.has('Position')" class="help-block">{{
											errors.first('Position')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Employee No." label-for="employeeNo" description>
										<b-form-input name="Employee No" type="text" class="numFont"
											v-model="form.employeeNo" v-validate="{
												required: true,
												regex: employeeNoRegex,
											}" placeholder="Employee No." maxlength="12" />
										<span v-show="errors.has('Employee No')" class="help-block">{{
											errors.first('Employee No')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Access Type" label-for="type" description>
										<div v-if="form.type === superAdminRole">
											<b>{{ form.type }}</b>
										</div>
										<b-form-select v-else name="Type" v-model="form.type" v-validate="'required'"
											:options="userTypes" class="mb-3" />
										<span v-show="errors.has('Type')" class="help-block">{{
											errors.first('Type')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Contact No." label-for="contactNo" description>
										<b-form-input name="Contact No" type="text" class="numFont"
											v-model="form.contactNo" v-validate="{
												required: contactNoIsRequired,
												regex: contactNoRegex,
											}" placeholder="+639xxxxxxxxx" maxlength="13" autocomplete="off" />
										<span v-show="errors.has('Contact No')" class="help-block">
											{{ 'Contact No. must follow this format: +639xxxxxxxxx' }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Account">
							<b-row class="my-2">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Email Address" label-for="emailAddress"
										description="Email address will be used as the username of this user's account">
										<b-form-input name="Email Address" type="text" v-model="form.emailAddress"
											placeholder="Email Address" :readonly="true" />
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Password" label-for="password" description>
										<b-button variant="link"
											v-b-tooltip.hover.top="'Send reset password link to the user\'s email'"
											@click="sendResetPassword" class="mr-1">
											Reset Password
										</b-button>
										<br />
										<b-button variant="link"
											v-b-tooltip.hover.top="'Set the user\'s account to NEW USER status to allow update password on next login'"
											@click="revertToNewAccount" class="mr-1">
											Reset to New Account
										</b-button>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Subscription">
							<b-row class="my-2">
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Status">
										<b-form-radio-group v-model="form.isActive" :options="statusOptions"
											name="isActive" />
									</b-form-group>
								</b-col>

								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Has Notification?">
										<b-form-radio-group v-model="form.hasNotif" :options="yesNoOptions" />
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Enable 2-Way Auth?">
										<b-form-radio-group v-model="form.has2WayAuth" :options="yesNoOptions" />
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>
					</b-tabs>
				</div>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { UserUtil } from '@/utils/userutil';
import { ValidationUtil } from '@/utils/validationUtil';

// API
import userApi from '@/api/userApi';

// Others
import { firebase } from '@/config/firebase';
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'edit-user',
	components: {
		Loading,
	},
	props: {
		companyOptions: {
			type: Array,
			required: true,
		},
		allCompaniesObj: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			form: {
				id: '',
				firstName: '',
				middleName: '',
				lastName: '',
				company: '',
				companyId: '',
				position: '',
				employeeNo: '',
				type: null,
				contactNo: '',
				emailAddress: '',
				isActive: 'true',
				hasNotif: false,
				has2WayAuth: false,
				isNewUser: true,
				companyAccess: [],
				dateCreated: '',
				createdBy: '',
				dateUpdated: '',
				updatedBy: '',
			},
			userTypes: config.userTypes,
			yesNoOptions: [
				{ text: 'YES', value: true },
				{ text: 'NO', value: false },
			],
			statusOptions: config.statusOptions,

			selCompany: { ...config.companyDefaultValue },
			loggedUser: this.$store.getters.loggedUser,
			superAdminRole: config.superAdminRole,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		contactNoIsRequired() {
			return this.form.has2WayAuth;
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.form.firstName + ' ' + this.form.lastName;
		},
		nameRegex() {
			return config.nameRegex;
		},
		contactNoRegex() {
			return config.contactNoRegex;
		},
		emailRegex() {
			return config.emailRegex;
		},
		positionRegex() {
			return config.positionRegex;
		},
		employeeNoRegex() {
			return config.employeeNoRegex;
		}
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			this.form = {
				...this.form,
				company: this.selCompany.name,
				companyId: this.selCompany.id
			};

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				this.isLoading = false;
				return;
			} else if (ValidationUtil.objectHasField('employeeNo', this.form.employeeNo, this.allUsersObj)
				&& this.form.employeeNo !== this.$store.getters.currUser.employeeNo) {
				this.$toaster.warning('Employee number ' + this.form.employeeNo + ' already exists.');
				this.isLoading = false;
				return;
			} else if (UserUtil.exceedMaximum(this.allCompaniesObj[this.selCompany.id], this.allUsersObj, this.form)) {
				this.$toaster.warning('You have exceeded the allowed no of active users for this company.');
				this.isLoading = false;
				return;
			} else if (!UserUtil.allowManagerAccount(this.form, this.allUsersObj)) {
				this.$toaster.warning('You can only have 3 managers accounts within a company.');
				this.isLoading = false;
				return;
			} else if (this.loggedUser.id === this.form.id && this.form.isActive === 'false') {
				this.$toaster.warning('You can\'t deactivate your account that is currently logged-in.');
				this.isLoading = false;
				return;
			}

			await this.handleSubmit();
		},
		getUserObject(param) {
			return {
				id: param.id,
				firstName: param.firstName,
				middleName: param.middleName,
				lastName: param.lastName,
				company: param.company,
				companyId: param.companyId,
				position: param.position,
				employeeNo: param.employeeNo,
				type: param.type,
				emailAddress: param.emailAddress,
				contactNo: param.contactNo,
				isActive: param.isActive,
				isNewUser: param.isNewUser,
				isPasswordExpired: param.isPasswordExpired,
				passwordExpirationDate: param.passwordExpirationDate,
				hasNotif: param.hasNotif,
				has2WayAuth: param.has2WayAuth,
				companyAccess: param.companyAccess,
				dateCreated: parseInt(param.dateCreated),
				createdBy: param.createdBy,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				updatedBy: this.loggedUser.id,
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			this.processFormValues();

			try {
				let userObj = this.getUserObject(this.form);
				let { data } = await userApi.updateUser(
					userObj,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit('onCloseSaveUser', data.user);
					this.$refs.modal.hide();
				} else {
					let error = data.errors[0];
					if (
						(!_.isEmpty(error.code)
							&& error.code === 'auth/email-already-exists')
						|| error.code === 'auth/phone-number-already-exists') {
						this.$toaster.error(error.message);
					} else {
						this.$toaster.error(error);
					}
				}

			} catch (_error) {
				this.$toaster.error(`Error updating user "${this.name}". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		processFormValues() {
			// Removes excess whitespace
			this.form.firstName = ValidationUtil.removeExcessWhiteSpace(
				this.form.firstName
			);
			this.form.lastName = ValidationUtil.removeExcessWhiteSpace(
				this.form.lastName
			);
			this.form.middleName = ValidationUtil.removeExcessWhiteSpace(
				this.form.middleName
			);
			this.form.position = ValidationUtil.removeExcessWhiteSpace(
				this.form.position
			);

			// Assign the selected company to the user
			this.form.company = this.selCompany.name;
			this.form.companyId = this.selCompany.id;
		},

		sendResetPassword() {
			firebase
				.auth()
				.sendPasswordResetEmail(this.form.emailAddress)
				.then(() => {
					this.$toaster.success("Reset Password Link was sent to user's email");
				})
				.catch(() => {
					this.$toaster.error("There was a problem encountered sending the reset password link to user's email.");
				});
		},

		async revertToNewAccount() {
			try {
				// show loading indicator
				this.isLoading = true;

				let userObj = this.getUserObject(this.form);

				let { data } = await userApi.resetToNewUser(
					userObj,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					this.form = data.user;
					this.$toaster.success(data.message);
				} else {
					let error = data.errors[0];
					this.$toaster.error(error);
				}

			} catch (_error) {
				this.$toaster.error(`Error user "${this.name}" reset to new state. Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},

		onReset() {
			/* Reset our form values */
			this.form = { ...this.$store.getters.currUser };

			// init company access
			if (_.isEmpty(this.form.companyAccess)) {
				this.form.companyAccess = UserUtil.getDefaultCompanyAccess(
					this.form,
					this.allCompaniesObj
				);
			}

			// init company
			let companyId = this.form.companyId;
			this.selCompany = DropDownItemsUtil.getCompanyItem(
				this.allCompaniesObj[companyId]
			);

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
	},
};
</script>