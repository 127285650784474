<template>
	<b-modal id="activate-user" :title="modalTitle" ok-title="Save" ref="modal" @cancel="handleClose" @ok="handleOk"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<div class="confirm-message">
			Are you sure you want to
			<b>{{ isActive === 'true' ? 'deactivate' : 'activate' }}</b>
			this user <b>"{{ name }}"</b>?
		</div>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { UserUtil } from '@/utils/userutil';

// API
import userApi from '@/api/userApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'user-account-activation',
	components: {
		Loading,
	},
	props: {
		allCompaniesObj: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			selUser: {},
			isActive: true,

			// Check for loader
			isLoading: false,

			loggedUser: this.$store.getters.loggedUser,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.selUser.firstName + ' ' + this.selUser.lastName;
		},
		modalTitle() {
			return this.isActive === 'true' ? 'Deactivate User' : 'Activate User';
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelUser', (user) => {
			this.selUser = user;
			this.isActive = user.isActive;
		});
	},
	methods: {
		toggleStatus(isActive) {
			let status = 'true';
			if (isActive === 'true') {
				status = 'false';
			}
			return status;
		},
		resetStatus() {
			this.selUser.isActive = this.isActive;
			this.$store.commit('SET_CURR_USER', this.selUser);
		},
		handleClose() {
			this.resetStatus();
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			if (this.isActive === 'false') {
				let currCompanyId = this.selUser.companyId;
				if (this.allCompaniesObj[currCompanyId].isActive === 'false') {
					this.$toaster.warning(`User account company is inactive.`);
					return;
				} else if (
					UserUtil.exceedMaximum(
						this.allCompaniesObj[currCompanyId],
						this.allUsersObj
					)
				) {
					this.$toaster.warning(
						`You have exceeded the allowed no of active users for this company.`
					);
					return;
				}
			}

			await this.handleSubmit();
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			// update the status and timestamp
			this.selUser.isActive = this.toggleStatus(this.isActive);
			this.selUser.updatedBy = this.loggedUser.id;
			this.selUser.dateUpdated = DateUtil.getCurrentTimestamp();

			try {
				let { data } = await userApi.updateUser(
					this.selUser,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					this.$refs.modal.hide();
					let successMsg = '';
					if (data.user.isActive === 'true') {
						successMsg = `User "${this.name}" account is now activated!`;
					} else {
						successMsg = `User "${this.name}" account is now deactivated!`;
					}

					this.$toaster.success(successMsg);
					EventBus.$emit('onCloseSaveUser', data.user);
				} else {
					let errorMsg = '';
					if (this.toggleStatus(this.isActive) === 'true') {
						errorMsg = `There's a problem encountered during account activation of the user ${this.name}"`;
					} else {
						errorMsg = `There's a problem encountered during account deactivation of the user ${this.name}"`;
					}

					this.resetStatus();
					this.$toaster.error(errorMsg);
				}
			} catch (_error) {
				let errorMsg = '';
				if (this.toggleStatus(this.isActive) === 'true') {
					errorMsg = `There's a problem encountered during account activation of the user ${this.name}"`;
				} else {
					errorMsg = `There's a problem encountered during account deactivation of the user ${this.name}"`;
				}

				this.resetStatus();
				this.$toaster.error(errorMsg);
			}

			// hide loading indicator
			this.isLoading = false;
		},
	},
};
</script>
