<template>
	<b-card no-body>
		<b-tabs card>
			<b-tab title="Primary Information" active>
				<b-card-text>
					<b-form-group label="Name" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{
							row.item.firstName +
							' ' +
							row.item.middleName +
							' ' +
							row.item.lastName
						}}
					</b-form-group>
					<b-form-group label="Position" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.position }}
					</b-form-group>
					<b-form-group label="Employee No." label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span class="numFont">
							{{
								row.item.employeeNo && row.item.employeeNo.length > 0
								? row.item.employeeNo
								: '-'
							}}
						</span>
					</b-form-group>
					<b-form-group label="Type" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.type }}
					</b-form-group>
					<b-form-group label="Email Address" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.emailAddress }}
					</b-form-group>
					<b-form-group label="Contact No." label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span class="numFont">
							{{ row.item.contactNo ? row.item.contactNo : '-' }}
						</span>
					</b-form-group>

					<b-form-group label="Date Created" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getFormattedDateWithTime(row.item.dateCreated) }}
					</b-form-group>

					<b-form-group label="Date Updated" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getFormattedDateWithTime(row.item.dateUpdated) }}
					</b-form-group>
				</b-card-text>
			</b-tab>
			<b-tab title="Subscription">
				<b-card-text>
					<b-form-group label="Status" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span v-if="row.item.isActive === 'true'">
							<b-badge variant="success">Active</b-badge>
						</span>
						<span v-else>
							<b-badge variant="secondary">Inactive</b-badge>
						</span>
					</b-form-group>
					<b-form-group label="Has Notification?" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span v-if="row.item.hasNotif">
							<b-badge variant="success">YES</b-badge>
						</span>
						<span v-else>
							<b-badge variant="secondary">NO</b-badge>
						</span>
					</b-form-group>
					<b-form-group label="Enable 2-Way Auth?" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span v-if="row.item.has2WayAuth">
							<b-badge variant="success">YES</b-badge>
						</span>
						<span v-else>
							<b-badge variant="secondary">NO</b-badge>
						</span>
					</b-form-group>
				</b-card-text>
			</b-tab>
			<b-tab title="Company Access">
				<b-card>
					<b-row>
						<b-col md="12" sm="12" class="my-1">
							<b-table show-empty striped hover :items="companyAccess" :fields="fields"
								:current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
								:sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>
								<template v-slot:cell(index)="row">{{
									row.index + 1
								}}</template>
							</b-table>
						</b-col>
						<b-col md="8" sm="12" class="my-1">
							<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
						</b-col>
						<b-col md="4" sm="12" class="my-1">
							<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
								class="my-0" />
						</b-col>
					</b-row>
				</b-card>
			</b-tab>
			<b-tab title="Change Logs">
				<b-col sm="12" class="mt-2">
					<UserChangeLogsDetailsView :row="row" />
				</b-col>
			</b-tab>
		</b-tabs>
	</b-card>
</template>

<script>
// Component
import UserChangeLogsDetailsView from './UserChangeLogsDetailsView';

// Util
import { DateUtil } from '@/utils/dateutil';

export default {
	name: 'user-details-view',
	components: {
		UserChangeLogsDetailsView
	},
	props: {
		row: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			companyAccess: [],
			fields: [
				{
					key: 'index',
					label: '#',
					sortable: false,
				},
				{
					key: 'name',
					label: 'Name',
					sortable: true,
				},
				{
					key: 'description',
					label: 'Description',
					sortable: true,
				},
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,
		};
	},
	mounted() {
		this.companyAccess = this.row.item.companyAccess
			? this.row.item.companyAccess
			: [];
		this.totalRows = this.companyAccess.length;
	},
	methods: {
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	},
};
</script>